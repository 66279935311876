import React, { useState } from 'react';
import {
    Box, Typography, Button, Select, MenuItem, TextField, IconButton
} from '@mui/material';
import { Add, Delete } from '@mui/icons-material';
import { getFirebaseFirestore, SERENE_USERS } from '../../../const';
import { doc, setDoc, updateDoc } from 'firebase/firestore';

// Days of the week
const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

const defaultSlots = {
    startTime: '10:00',
    endTime: '17:00', // 5 PM
};

const sessionLengths = [15, 30, 45, 60]; // in minutes

const ScheduleSetting = ({ onSuccess, userId }: { onSuccess: any, userId: string }) => {
    const [availability, setAvailability] = useState<any>(() =>
        daysOfWeek.reduce((acc, day) => {
            acc[day] = [{ ...defaultSlots }]; // Initialize each day with default slot
            return acc;
        }, {} as Record<string, { startTime: string; endTime: string }[]>)
    );

    const [sessionLength, setSessionLength] = useState(30); // Default session length (in minutes)

    // Handle adding new slot
    const handleAddSlot = (day: string) => {
        setAvailability((prev: any) => ({
            ...prev,
            [day]: [...prev[day], { ...defaultSlots }],
        }));
    };

    // Handle removing a slot
    const handleRemoveSlot = (day: string, index: number) => {
        setAvailability((prev: any) => ({
            ...prev,
            //@ts-ignore
            [day]: prev[day].filter((_, i) => i !== index),
        }));
    };

    // Handle time change
    const handleTimeChange = (day: string, index: number, field: 'startTime' | 'endTime', value: string) => {
        setAvailability((prev: any) => ({
            ...prev,
            [day]: prev[day].map((slot: any, i: any) =>
                i === index ? { ...slot, [field]: value } : slot
            ),
        }));
    };

    const saveAvailability = async () => {
        const db = getFirebaseFirestore(); // Initialize Firestore
        const availabilityData = {};

        // Structure the availability data
        for (const day in availability) {
            //@ts-ignore
            availabilityData[day] = availability[day].map((slot: any) => ({
                startTime: slot.startTime,
                endTime: slot.endTime,
            }));
        }

        try {
            // Save to Firestore
            await updateDoc(doc(db, SERENE_USERS, userId), { availability: availabilityData });
            onSuccess()
        } catch (error) {
            console.error('Error saving availability:', error);
        }
    };

    return (
        <Box p={6}>
            <Box display="flex" flexWrap="wrap" justifyContent="space-between" gap={2}>
                {daysOfWeek.map((day) => (
                    <Box key={day} width={{ xs: '100%', md: '30%' }} mb={3}>
                        <Typography variant="body1" fontWeight={"bolder"}>{day}</Typography>
                        <br />
                        {availability[day].length === 0 ? (
                            <Box display="flex" alignItems="center" gap={2} mb={1}>
                                <TextField
                                    label="Start Time"
                                    value="-"
                                    disabled
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                />
                                <TextField
                                    label="End Time"
                                    value="-"
                                    disabled
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                />
                                <IconButton >
                                    <Delete />
                                </IconButton>
                            </Box>
                        ) : (
                            //@ts-ignore
                            availability[day].map((slot, index) => (
                                <Box key={index} display="flex" alignItems="center" gap={2} mb={1}>
                                    <TextField
                                        type="time"
                                        label="Start Time"
                                        value={slot.startTime}
                                        onChange={(e) => handleTimeChange(day, index, 'startTime', e.target.value)}
                                        InputLabelProps={{ shrink: true }}
                                        fullWidth
                                    />
                                    <TextField
                                        type="time"
                                        label="End Time"
                                        value={slot.endTime}
                                        onChange={(e) => handleTimeChange(day, index, 'endTime', e.target.value)}
                                        InputLabelProps={{ shrink: true }}
                                        fullWidth
                                    />
                                    <IconButton onClick={() => handleRemoveSlot(day, index)}>
                                        <Delete />
                                    </IconButton>
                                </Box>
                            ))
                        )}
                        <Button
                            variant="outlined"
                            startIcon={<Add />}
                            onClick={() => handleAddSlot(day)}
                        >
                            Add Slot
                        </Button>
                    </Box>
                ))}
            </Box>

            <Box mt={4}>
                <Typography variant="h6">Session Length</Typography>
                <br />
                <Select
                    value={sessionLength}
                    onChange={(e) => setSessionLength(e.target.value as number)}
                    displayEmpty
                    fullWidth
                >
                    {sessionLengths.map((length) => (
                        <MenuItem key={length} value={length}>
                            {`${length} minutes`}
                        </MenuItem>
                    ))}
                </Select>
            </Box>
            <br />
            <Box mt={4} display="flex" justifyContent="center">
                <Button
                    onClick={saveAvailability}
                    variant="outlined"
                    color="primary"
                    size='large'
                    sx={{
                        borderRadius: "20px", '&:hover': {
                            opacity: 1, // Keep the opacity the same,
                        },
                    }}
                >
                    Continue
                </Button>
            </Box>
        </Box>
    );
};

export default ScheduleSetting;
