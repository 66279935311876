import { Button, Typography } from "@mui/material";
import landingpageStyle from "../style/section-two.module.css";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

const SectionTwo = () => {

    const navigate = useNavigate()

    return (
        <motion.div className={landingpageStyle.second_section} initial={{ opacity: 0 }} whileInView={{ opacity: 1 }}
            transition={{ duration: 0.5 }} exit={{ opacity: 0 }}>
            <div className={landingpageStyle.into_container}>
                <div>
                    <div className={landingpageStyle.second_section_title}>Get Started In Minutes 🦋</div>
                    <br />
                    <div className={landingpageStyle.second_section_subtitle}>

                        <Typography variant="h5">
                            Serene offers comprehensive, AI-powered tools tailored to both private practitioners and mental health organizations. Whether you're running a <span className="bold">solo practice</span> or part of an <span className="bold">organization</span>, we provide solutions designed to elevate the care you deliver and streamline your workflow.
                        </Typography>
                    </div>


                    <br />
                    <br />
                    <br />
                    <br />
                    {/* <img src={SereneDesc} />
                    <br />
                    <br />
                    <br />
                    <br /> */}
                    <motion.div initial={{ opacity: 0 }} whileInView={{ opacity: 1 }}
                        transition={{ duration: 0.5 }}>
                        <Typography variant="h5" align="center">We provide the <strong>Best AI powered Tech</strong> so that you <strong>can provide the Best Care</strong></Typography>
                    </motion.div>
                    <br />
                    <br />
                </div>
            </div>

            <br />
            <motion.div className={landingpageStyle.button_container} initial={{ opacity: 0 }} whileInView={{ opacity: 1 }}
                transition={{ duration: 0.5 }}>
                <Button onClick={() => { navigate("/login") }} variant="contained" size="large" sx={{ borderRadius: 30, height: 60, width: 300 }}>Create Your Free Account</Button>
            </motion.div>
        </motion.div>)
}

export default SectionTwo;