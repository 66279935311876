import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from "@mui/material"
import sereneProfileStyle from "./style/serene-profile.module.css"
import SwitchAccountIcon from '@mui/icons-material/SwitchAccount';
import { useNavigate } from "react-router-dom";
import CancelIcon from '@mui/icons-material/Cancel';
import { useSelector } from "react-redux";
import { AppState } from "../../types";
import moment from "moment";
import { Edit } from "../../components/edit";
import { useState } from "react";
import {

    TextInput,
} from "flowbite-react";

export const SereneProfile = () => {
    const navigate = useNavigate()
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [editedName, setEditedName] = useState('');
    const [editedEmail, setEditedEmail] = useState('');
    const sereneUser = useSelector((state: AppState) => state.sereneUser)

    const handleOpenEditDialog = () => {
        setEditedName(sereneUser.displayName);
        setEditedEmail(sereneUser.email);
        setOpenEditDialog(true);
    };

    // Close the dialog box
    const handleCloseEditDialog = () => {
        setOpenEditDialog(false);
    };

    const handleSaveEdit = () => {
        setOpenEditDialog(false);
    };

    const renderEditDialog = () => {
        return (
            <Dialog open={openEditDialog} onClose={(event, reason) => {
                if (reason !== 'backdropClick') {
                    handleCloseEditDialog();
                }
            }} fullWidth maxWidth="sm"
                BackdropProps={{
                    style: {
                        backgroundColor: 'rgba(0, 0, 0, 0.8)', // Darker background with 80% opacity
                    },
                }}>
                <br />
                <DialogContent>
                    <div className="my-4">
                        <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900">
                            Name
                        </label>
                        <TextInput
                            id="name"
                            type="text"
                            value={editedName}
                            onChange={(e) => setEditedName(e.target.value)}
                            required={true}
                        />
                    </div>
                    <div className="my-4">
                        <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900">
                            Email
                        </label>
                        <TextInput
                            id="email"
                            type="email"
                            value={editedEmail}
                            onChange={(e) => setEditedEmail(e.target.value)}
                            required={true}
                        />
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseEditDialog}>Cancel</Button>
                    <Button onClick={handleSaveEdit} sx={{ borderRadius: "5px", marginRight: "20px" }}>Save</Button>
                </DialogActions>
                <br />
            </Dialog>
        );
    };


    return <div className={sereneProfileStyle.container}>
        <div className={sereneProfileStyle.body}>

            <div className={sereneProfileStyle.header}>
                <div>
                    <div className={sereneProfileStyle.title}><SwitchAccountIcon sx={{ fontSize: "50px", color: "black" }} />
                        <Typography variant="h3" fontWeight={"bolder"}>Profile</Typography>
                    </div>
                </div>
                <div onClick={() => navigate(-1)}>
                    <CancelIcon fontSize="large" sx={{ cursor: "pointer", fontSize: "45px" }} />
                </div>
            </div>
            <div className={sereneProfileStyle.basic_container}>
                <img src={sereneUser.displayPicture} className={sereneProfileStyle.display_picture} />
                <div className={sereneProfileStyle.basic_details}>
                    <div className={sereneProfileStyle.row}>
                        <Typography variant="h4" fontWeight={"bolder"}>{sereneUser.displayName}</Typography>
                        <div className={sereneProfileStyle.edit_container}><Edit onClick={() => { setOpenEditDialog(true) }} /></div>
                    </div>
                    <div className={sereneProfileStyle.spacer} />
                    <div className={sereneProfileStyle.row}>
                        <Typography variant="body1">📩 {sereneUser.email}</Typography>


                    </div>
                    <div className={sereneProfileStyle.row}>
                        <Typography variant="body1">🕐 Member since <strong>{moment(sereneUser.creationTime ?? 0).format("MMMM DD YYYY")}</strong></Typography>
                    </div>
                </div>
            </div>
        </div>
        {renderEditDialog()}
    </div>
}