import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getFirebaseFirestore, SERENE_USERS, USER_COLLECTION } from "../const";
import { collection, doc, getDoc, getDocs, getFirestore, query, setDoc, updateDoc, where } from "firebase/firestore";
import { Sentiment } from "../types";


export const getUser = (callback: any) => {
    const auth = getAuth();
    onAuthStateChanged(auth, async (user) => {
        if (user) {
            const db = getFirestore()
            const uid = user.uid;
            const userRef = doc(db, USER_COLLECTION, uid)
            const userData = await getDoc(userRef)
            callback(userData.data())
        } else {
            callback(null)
        }
    });
}

export const generateUniqueUsername = async (email: string, userId: string) => {
    const db = getFirebaseFirestore(); // Initialize Firestore
    const usernameBase = email.split('@')[0];

    // First, get the user's document using the user ID
    const userDocRef = doc(db, 'serene_users', userId);
    const userDoc = await getDoc(userDocRef);
    let existingUsername = userDoc?.data()?.username ?? "";

    // If the username exists and is not an empty string, return it
    if (existingUsername) {
        return existingUsername;
    }

    let uniqueUsername = '';
    let isUnique = false;
    const maxAttempts = 10; // Maximum attempts for generating a unique username
    let attempts = 0;

    // Function to generate a new username
    const createUsername = (): string => {
        const randomNum = Math.floor(1000 + Math.random() * 9000); // Generates a number between 1000 and 9999
        return `${usernameBase}${randomNum}`;
    };

    while (!isUnique && attempts < maxAttempts) {
        uniqueUsername = createUsername();

        // Query to check if the username already exists in the "serene_users" collection
        const usernameQuery = query(
            collection(db, SERENE_USERS), // Adjust the collection path
            where('username', '==', uniqueUsername)
        );

        const usernameQuerySnapshot = await getDocs(usernameQuery);

        if (usernameQuerySnapshot.empty) {
            isUnique = true; // Username is unique, exit loop
        }

        attempts++; // Increment attempts counter
    }

    // Check if a unique username was found
    if (!isUnique) {
        throw new Error("Unable to generate a unique username after 10 attempts.");
    }

    // Update the user's document with the unique username
    const userDocRefToUpdate = doc(db, SERENE_USERS, userId);
    await updateDoc(userDocRefToUpdate, { username: uniqueUsername });

    return uniqueUsername;
};
// export const calculateSentiment = (sentiments?: Sentiment[]) => {
//     let neutralCount = 0;
//     let positiveCount = 0;
//     let negativeCount = 0;

//     sentiments?.forEach((sentiment) => {
//         switch (sentiment.sentiment) {
//             case "neutral":
//                 neutralCount++;
//                 break;
//             case "positive":
//                 positiveCount++;
//                 break;
//             case "negative":
//                 negativeCount++;
//                 break;
//         }
//     });

//     const totalSentiments = neutralCount + positiveCount + negativeCount;
//     let positivePercent = positiveCount / totalSentiments;
//     let negativePercent = negativeCount / totalSentiments;
//     let neutralPercent = neutralCount / totalSentiments;

//     if (isNaN(positivePercent)) {
//         positivePercent = 0;
//     }

//     if (isNaN(negativePercent)) {
//         negativePercent = 0;
//     }

//     if (isNaN(neutralPercent)) {
//         neutralPercent = 0;
//     }



//     return { positivePercent, negativePercent, neutralPercent };
// }