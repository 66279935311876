import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getFirebaseFirestore, SERENE_USERS } from "../../../const";
import { collection, getDocs, query, where } from "firebase/firestore";
import { Box, Typography, Avatar, Grid, Paper } from "@mui/material";

export const PublicSereneUser = () => {
    const { userName } = useParams();
    const [userData, setUserData] = useState<any>(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");

    useEffect(() => {
        if (userName === "") {
            return;
        }
        const fetchUserData = async () => {
            const db = getFirebaseFirestore();
            try {
                // Create a query to find the user document by username
                const userQuery = query(
                    collection(db, SERENE_USERS), // The collection path
                    where('username', '==', userName) // Query by username
                );

                const querySnapshot = await getDocs(userQuery);

                if (!querySnapshot.empty) {
                    // If the query returns results, get the first document
                    const userDoc = querySnapshot.docs[0].data();
                    setUserData(userDoc);
                } else {
                    setError('User not found');
                }
            } catch (err) {
                setError('Failed to fetch user data');
                console.error(err);
            } finally {
                setLoading(false);
            }
        };

        fetchUserData();
    }, [userName]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    return (
        <Box sx={{ padding: 4, minHeight: '100vh' }}>

            <Box display="flex" alignItems="center" mb={3}>
                <Avatar
                    alt={userData.display_name}
                    src={userData.display_picture}
                    sx={{ width: 150, height: 150, marginRight: 2 }}
                />
                <Typography variant="h3" gutterBottom>
                    {userData.display_name}
                </Typography>
            </Box>
            {/* <Typography variant="h6" color="textSecondary" gutterBottom>
                Email: {userData.email}
            </Typography>

            <Typography variant="h5" gutterBottom>
                Availability
            </Typography> */}


        </Box>
    );
};
