import { createSlice } from '@reduxjs/toolkit'

/**
 * Serene user data
 */
export const sereneUserSlice = createSlice({
    name: 'serene_user',
    initialState: {
        fetched: false,
        id: "",
        displayName: "",
        email: "",
        calenderConnected: false,
        calenderToken: "",
        calenderRefreshToken: "",
        displayPicture: "",
        creationTime: 0,
        userName: ""
    },
    reducers: {
        updateUser: (state, action) => {
            state.fetched = true
            state.displayName = action.payload["display_name"] ?? ""
            state.id = action.payload["id"] ?? ""
            state.email = action.payload["email"] ?? ""
            state.calenderConnected = action.payload["calender_connected"] ?? false
            state.calenderToken = action.payload["calender_token"] ?? ""
            state.calenderRefreshToken = action.payload["calender_refresh_token"] ?? ""
            state.displayPicture = action.payload["display_picture"] ?? ""
            state.creationTime = action.payload["creation_time"] ?? 0
            state.userName = action.payload["username"] ?? ""
        },
    },
})

// Action creators are generated for each case reducer function
export const { updateUser, } = sereneUserSlice.actions

export default sereneUserSlice.reducer

